import React from "react"
import Layout from "../components/layout"
import Seo from '../components/seo'

const UnderConstruction = () => (
    <Layout>
      <main>
        <section className="banner">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="banner__copy">
                  <h1>UNDER CONSTRUCTION</h1>
                  <p className="p--hg u-color-white my-5 my-lg-4">
                    This page will be available soon.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
)


  export const Head = ({ location }) => {
    const title="This page is under construction"
    const description="This page is under construction"
    
    return <Seo type="page" title={title} description={description} />
  } 
  
export default UnderConstruction;